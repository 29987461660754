<template>
  <c-box
    w="100%"
    mx="auto"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="[
          {
            label: 'Dashboard',
            href: { name: 'client.index' }
          },
          {
            label: 'Akun',
            href: { name: 'client.profile' }
          },
          {
            label: 'Riwayat Program',
            isCurrent: true,
          },
        ]"
      />
    </Portal>
    <c-flex
      flex-direction="column"
      :bg="['#F2F2F2', 'white']"
      :border="['none', '1px solid #f2f2f2']"
      :box-shadow="['unset', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['unset', '16px']"
      w="100%"
      :p="['0px', '24px']"
      font-family="Roboto"
    >
      <c-flex
        v-if="isEmpty"
        flex-direction="column"
        justify="center"
        align-items="center"
        font-family="Roboto"
      >
        <EmptyCard
          imgalt="Riwayat Program"
          label="Anda belum memiliki riwayat Pembelian"
        />
        <c-button
          as="router-link"
          to="/program"
          width="269px"
          :height="['50px', '50px']"
          border-radius="full"
          border="2px solid #008C81"
          background="#008C81"
          :font-size="['14px', '18px']"
          color="#FFFFFF"
          mb="60px"
          line-height="21px"
        >
          Lihat Program
        </c-button>
      </c-flex>
      <c-flex
        v-if="!isEmpty"
        margin="auto"
        flex-direction="column"
        w="100%"
        justify-content="center"
        align-content="center"
        p="16px"
        pos="relative"
      >
        <c-heading
          :w="['100%', '80%']"
          :display="['none', 'block']"
          margin="auto"
          align="left"
          font-size="28px"
          font-weight="700"
          margin-bottom="20px"
        >
          Riwayat Program
        </c-heading>

        <c-flex
          :w="['100%', '80%']"
          margin="auto"
          justify="space-between"
          :mb="['10px', '20px']"
          gap="5px"
        >
          <c-button
            v-for="(item, index) in menus"
            :key="index"
            v-chakra="{
              '&.active, &:focus, &:active': {
                color: '#FFFFFF',
                backgroundColor: '#008C81',
                border: '2px solid #008C81',
              },
            }"
            color="primary.400"
            :class="{ active: item.status == active }"
            width="100%"
            :height="['34px', '50px']"
            border-radius="full"
            border="2px solid #008C81"
            background="#FFFFFF"
            :font-size="['12px', '16px']"
            font-weight="500"
            @click="selectMenu(item)"
          >
            {{ item.menu }}
          </c-button>
        </c-flex>

        <c-box
          display="flex"
          flex-direction="column"
          :gap="['10px', '20px']"
        >
          <ProgramHistoryCard
            v-for="item in filterPrograms"
            :key="item.id"
            :title="item.name"
            :level="item.programService"
            :ahli-gizi="item.nutritionist"
            :duration="item.duration"
            :status="item.status"
            :date-start="item.startAt"
            :date-end="item.endAt"
            :price="item.productPrice"
            :coupon="item.couponName"
            :img="item?.product?.photoUrl"
            cursor-pointer="pointer"
            @click.native="detailProgram(item)"
          />
        </c-box>
      </c-flex>
    </c-flex>
  </c-box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import EmptyCard from './empty.vue'
import ProgramHistoryCard from './program-history-card.vue'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import EventBus from '@/utils/event-bus'

export default {
  name: 'ProfileProgramHistory',
  components: {
    ProgramHistoryCard,
    EmptyCard,
    BreadcrumbPath,
  },
  data() {
    return {
      active: 'all',
      menus: [
        {
          id: 1,
          menu: 'Seluruh Program',
          status: 'all',
        },
        {
          id: 2,
          menu: 'Program Aktif',
          status: 'active',
        },
        {
          id: 3,
          menu: 'Program Selesai',
          status: 'done',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      programs: 'profileProgram/history',
      isAuthenticated: 'auth/isAuthenticated',
    }),
    isEmpty() {
      if (this.programs.length === 0) {
        return true
      }
      return false
    },
    filterPrograms() {
      if (this.active !== 'all') {
        return this.programs.filter((program) => {
          return program.status === this.active
        })
      }
      return this.programs
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.listRiwayatPrograms()
      },
    },
  },
  mounted() {
    if (!this.isAuthenticated) return this.$router.push({ name: 'auth', query: { tab: 'login' } })
    EventBus.$emit('HEADER_BACK', '/profile')
  },
  methods: {
    ...mapActions({
      listRiwayatPrograms: 'profileProgram/listHistoryPrograms',
    }),
    selectMenu(menu) {
      if (this.active != menu.status) {
        this.active = menu.status
      }
      // this.filterPrograms(menu.status)
    },
    detailProgram(program) {
      this.$router.push({ name: 'client.profile.programDetail', params: { programId: program.id } })
    },
  },
}
</script>

<style scoped>
  .active {
    border: 1px solid #008C81
  }
</style>
