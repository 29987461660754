import { render, staticRenderFns } from "./program-history.vue?vue&type=template&id=29de4986&scoped=true&"
import script from "./program-history.vue?vue&type=script&lang=js&"
export * from "./program-history.vue?vue&type=script&lang=js&"
import style0 from "./program-history.vue?vue&type=style&index=0&id=29de4986&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29de4986",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex, CHeading: require('@chakra-ui/vue').CHeading, CBox: require('@chakra-ui/vue').CBox})
